import * as rasterizeHTML from "rasterizehtml";

const svg = `
<svg xmlns="http://www.w3.org/2000/svg" version="1.1" baseProfile="full" width="300" height="200">
   <rect width="100%" height="100%" fill="red" />
   <circle cx="150" cy="100" r="80" fill="green" />
   <text x="150" y="125" font-size="60" text-anchor="middle" fill="white">SVG</text>
</svg>
`;

export function svgToPng(svg, callback) {
  const url = getSvgUrl(svg);
  svgUrlToPng(url, (imgData) => {
    callback(imgData);
    URL.revokeObjectURL(url);
  });
}

function getSvgUrl(svg) {
  return URL.createObjectURL(
    new Blob([svg], {
      type: "image/svg+xml",
    })
  );
}

function svgUrlToPng(svgUrl, callback) {
  const svgImage = document.createElement("img");
  document.body.appendChild(svgImage);
  svgImage.onload = () => {
    const canvas = document.createElement("canvas");
    canvas.width = svgImage.clientWidth;
    canvas.height = svgImage.clientHeight;
    const canvasCtx = canvas.getContext("2d");
    canvasCtx.drawImage(svgImage, 0, 0);
    const imgData = canvas.toDataURL("image/png");
    callback(imgData);
    document.body.removeChild(svgImage);
  };
  svgImage.src = svgUrl;
}

export const getCanvased = () => {
  let canvas = document.getElementById("canvas");
  let ctx = canvas.getContext("2d");

  let d = document.implementation.createHTMLDocument();
  d.body.appendChild(document.getElementById("avatar"));

  rasterizeHTML.drawDocument(d, canvas).then(function (renderResult) {
    ctx.drawImage(renderResult.image, -8, -8);

    let dataUrl = canvas.toDataURL();
    let blob = canvas.toBlob(function (blob) {
      let url = URL.createObjectURL(blob);
      console.log(blob);
      // let textbox = document.getElementById("avatar");
      // textbox.value = url;

      let output = document.getElementById("output");
      output.src = url;
    });
  });
};
