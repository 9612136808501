import Image from "./Image";
import UrlInput from "./UrlInput";
import ButtonAddLogo from "./ButtonAddLogo";
import ButtonAddBorder from "./ButtonAddBorder";
import html2canvas from "html2canvas";
import { isSafari } from "react-device-detect";
import { useUIContext } from "./uiContext";
import { saveAsPng } from "save-html-as-image";
import Frames from "./Frames";
import "./App.scss";
import Disclaimer from "./Disclaimer";
import ShowFrames from "./ShowFrames";
import ImageV2 from "./ImageV2";
import { getCanvased } from "./utils";

function App() {
  const { border, color, frame, back } = useUIContext();
  const downloadImage = (blob, fileName) => {
    const fakeLink = window.document.createElement("a");
    fakeLink.style = "display:none;";
    fakeLink.download = fileName;
    fakeLink.href = blob;
    document.body.appendChild(fakeLink);
    fakeLink.click();
    document.body.removeChild(fakeLink);
    fakeLink.remove();
  };

  const downloadImg = async () => {
    const node = document.getElementById("avatar");
    const canvas = await html2canvas(node, {
      useCORS: true,
      backgroundColor: "rgba(0,0,0,0.0)",
    });
    const image = canvas.toDataURL("image/png", 1.0);
    downloadImage(image, "avatar");
    // await saveAsPng(node, { filename: "avatar", printDate: false });
    getCanvased();
  };

  return (
    <div className="App">
      <img className="back" src={back} alt="back" />
      <a href="https://www.icdinos.io">
        <img className="main-logo" src="/logo.png" alt="IC Dinos Logo" />
      </a>
      <div className="wrapper">
        <div id="avatar" className="Image-wrapper">
          {!frame && border && color && (
            <div
              data={color}
              style={{ borderColor: color }}
              className="border"
            />
          )}

          <ShowFrames />
          <ImageV2 />
          {/* <Image /> */}
        </div>
        <div className="toggles">
          <UrlInput />
          <ButtonAddLogo />
          <ButtonAddBorder />
          <Frames />
          <button
            // disabled={!imgLoaded}
            className="button save-it"
            onClick={downloadImg}
          >
            <span> save it</span>
          </button>
          {isSafari && <Disclaimer />}
          {/* <HexColorPicker color={color} onChange={setColor} /> */}
        </div>
      </div>
    </div>
  );
}

export default App;
