import React from "react";
import { useUIContext } from "./uiContext";

export default function Frames() {
  const { frame, setFrame } = useUIContext();

  const frames = [
    "/frames/1.png",
    "/frames/2.png",
    "/frames/3.png",
    "/frames/4.png",
    "/frames/5.png",
  ];
  const handlePickFrame = (i, e) => {
    setFrame(i);
  };

  return (
    <div className="frames">
      <button
        onClick={() => {
          handlePickFrame(null);
        }}
        className={`frame-button none ${frame === null ? "picked" : ""}`}
      >
        none
      </button>
      {frames.map((f, i) => (
        <button
          key={i}
          className={"frame-button"}
          onClick={(e) => {
            handlePickFrame(i, e);
          }}
        >
          <img
            className={`frame ${frame === i ? "picked" : ""}`}
            src={f}
            alt="frame"
          />
        </button>
      ))}
    </div>
  );
}
