import styles from './spinner.module.scss';

export default function Spinner({ message }) {
  return (
    <div className={styles.spinnerContainer}>
      <svg
        className={styles.spinner}
        width="65px"
        height="65px"
        viewBox="0 0 66 66"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          className={styles.path}
          fill="none"
          stroke-width="6"
          stroke-linecap="round"
          cx="33"
          cy="33"
          r="30"
        ></circle>
      </svg>
      {message && <p>{message}</p>}
    </div>
  );
}
