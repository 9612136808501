import React from "react";
import { useUIContext } from "./uiContext";

export default function ShowFrames({ i }) {
  const { frame } = useUIContext();

  return (
    <div className="nft-frame">
      <img
        className={frame === 0 ? "picked" : ""}
        src="/frames/1.png"
        alt="frame 1"
      />
      <img
        className={frame === 1 ? "picked" : ""}
        src="/frames/2.png"
        alt="frame 2"
      />
      <img
        className={frame === 2 ? "picked" : ""}
        src="/frames/3.png"
        alt="frame 3"
      />
      <img
        className={frame === 3 ? "picked" : ""}
        src="/frames/4.png"
        alt="frame 4"
      />
      <img
        className={frame === 4 ? "picked" : ""}
        src="/frames/5.png"
        alt="frame 5"
      />
    </div>
  );
}
