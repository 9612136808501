import React from 'react';
import { useUIContext } from './uiContext';

export default function ButtonAddBorder() {
  const { border, setBorder } = useUIContext();
  const handleAddBorder = () => {
    setBorder(!border);
  };
  return (
    <button
      className={`button ${border ? '' : 'dark'}`}
      onClick={handleAddBorder}
    >
      <span>{border ? 'remove ' : 'add '}border</span>
    </button>
  );
}
