import React from "react";

export default function Disclaimer() {
  return (
    <div className="disclaimer button dark">
      Safari is having a few issues with this generator - if you are using
      Safari and image doesn't appear after adding, try reload page and add
      again. Sorry for any inconvenience.😥{" "}
    </div>
  );
}
