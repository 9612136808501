import React from 'react';
import { useUIContext } from './uiContext';

export default function ButtonAddImage() {
  const { inputValue, setImgSrc } = useUIContext();
  return (
    <button
      className="button add-image"
      onClick={() => {
        inputValue && setImgSrc(inputValue);
      }}
    >
      <span>Add </span>
    </button>
  );
}
