import { createContext, useContext, useState, useEffect } from 'react';
const UIContext = createContext();
const backs = [
  '/backs/1.jpg',
  '/backs/2.jpg',
  '/backs/3.jpg',
  '/backs/4.jpg',
  '/backs/5.jpg',
];
export function UIProvider({ children }) {
  const [inputValue, setInputValue] = useState('');
  const [imgSrc, setImgSrc] = useState(null);
  const [imgLoaded, setImgLoaded] = useState(false);
  const [addLogo, setAddLogo] = useState(true);
  const [border, setBorder] = useState(true);
  const [color, setColor] = useState(null);
  const [frame, setFrame] = useState(null);
  const [back, setBack] = useState();
  useEffect(() => {
    setBack(backs[Math.floor(Math.random() * backs.length)]);
  }, []);

  return (
    <UIContext.Provider
      value={{
        inputValue,
        setInputValue,
        imgSrc,
        setImgSrc,
        addLogo,
        setAddLogo,
        border,
        setBorder,
        color,
        setColor,
        frame,
        setFrame,
        back,
        imgLoaded,
        setImgLoaded,
      }}
    >
      {children}
    </UIContext.Provider>
  );
}

export function useUIContext() {
  return useContext(UIContext);
}
