import React from 'react';
import { usePalette } from 'react-palette';
export default function PaletteExtract({ imgSrc, setColor, loaded }) {
  const { data, loading, error } = usePalette(imgSrc);
  React.useEffect(() => {
    if (imgSrc && loaded && !loading) {
      setColor(data.darkMuted);
    }
  }, [imgSrc, loading, loaded]);
  return <div></div>;
}
