import React from 'react';
import { useUIContext } from './uiContext';

export default function ButtonAddLogo() {
  const { addLogo, setAddLogo } = useUIContext();
  const handleAddLogo = () => {
    setAddLogo(!addLogo);
  };
  return (
    <button
      className={`button ${addLogo ? '' : 'dark'}`}
      onClick={handleAddLogo}
    >
      <span>{addLogo ? 'remove ' : 'add '} logo</span>
    </button>
  );
}
