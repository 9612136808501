import axios from "axios";
import { useState, useEffect, useRef } from "react";
import DragMove from "./DragMove";
import { useImageLoaded } from "./hooks";
import PaletteExtract from "./PaletteExtract";
import Spinner from "./Spinner/Spinner";
import { useUIContext } from "./uiContext";
import { svgToPng } from "./utils";

function ImageV2() {
  const { imgSrc, addLogo, setColor, frame } = useUIContext();
  const [translate, setTranslate] = useState({
    x: 0,
    y: 0,
  });
  const [borders, setBorders] = useState({ x: 0, y: 0 });
  const [img, setImg] = useState(null);
  const nftAvatar = useRef(null);
  const nftImage = useRef(null);
  const borderWidth = frame ? 10 : -10;
  const handleDragMove = (e) => {
    const movedX = translate.x + e.movementX;
    const movedY = translate.y + e.movementY;

    setTranslate((prev) => ({
      x:
        Math.abs(movedX / 2) < borders.x + borderWidth && movedX <= 0
          ? movedX
          : movedX,
      y:
        Math.abs(movedY / 2) < borders.y + borderWidth && movedY <= 0
          ? movedY
          : movedY,
    }));
  };

  useEffect(() => {
    if (imgSrc) {
      axios.get(imgSrc).then((res) => {
        svgToPng(res.data, (imgData) => {
          const pngImage = document.createElement("img");
          const nftWrapper = document.getElementById("nft-img-wrapper");

          pngImage.classList.add("NFT-image");
          nftWrapper.appendChild(pngImage);
          pngImage.src = imgData;
          setTimeout(() => {
            setImg(true);
          }, 200);
        });
      });
    }
  }, [imgSrc]);

  useEffect(() => {
    if (imgSrc && img) {
      const nftAvHeight = nftAvatar?.current?.offsetHeight;
      const nftAvWidth = nftAvatar?.current?.offsetWidth;
      const nftImgHeight = nftImage?.current?.offsetHeight;
      const nftImgWidth = nftImage?.current?.offsetWidth;
      const borderX = (nftImgWidth - nftAvWidth) / 2;
      const borderY = (nftImgHeight - nftAvHeight) / 2;
      setBorders({ x: borderX, y: borderY });

      setTranslate({
        x: -borderX,
        y: -borderY * 2 + 5,
      });
    }
  }, [img, imgSrc]);

  return (
    <div ref={nftAvatar} className={`NFT-avatar  `}>
      {addLogo && <img className="logo" src="/logo.png" alt="dinos logo" />}
      {!imgSrc && (
        <div className="no-image">
          {" "}
          <p className="text">Please insert URL</p>
        </div>
      )}
      {imgSrc && !img && <Spinner message={"Loading your image..."} />}
      <DragMove onDragMove={handleDragMove}>
        <div
          id="nft-img-wrapper"
          ref={nftImage}
          style={{
            transform: `translateX(${translate.x}px) translateY(${translate.y}px)`,
          }}
        ></div>
      </DragMove>

      {img && (
        <PaletteExtract setColor={setColor} imgSrc={imgSrc} loaded={img} />
      )}
    </div>
  );
}

export default ImageV2;
