import React from 'react';
import ButtonAddImage from './ButtonAddImage';
import { useUIContext } from './uiContext';

export default function UrlInput() {
  const { setInputValue, inputValue } = useUIContext();
  return (
    <div className="url-wrapper">
      <input
        className="url-input"
        type="text"
        value={inputValue}
        placeholder="paste your nft url"
        onChange={(e) => {
          setInputValue(e.target.value);
        }}
      />
      <ButtonAddImage />
    </div>
  );
}
